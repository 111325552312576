<template>
  <div class="nav-options shadow">
    <router-link @click="hoverOut()" @mouseover="hoverElement" class="chat-link" to="/chat">chat</router-link>
    <router-link @click="hoverOut()" @mouseover="hoverElement" class="translate-link" to="/translate">translate</router-link>
    <router-link @click="hoverOut()" @mouseover="hoverElement" class="opal-link" to="/opal">opal</router-link>
    <a class="diss-link" target="_blank" rel="noopener noreferrer" @mouseover="hoverElement" href="/Dissertation.pdf">undergraduate dissertation&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" style="fill:white" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg></a>
    <div ref="hoverer" style="top:-100%;" class="hoverer">&nbsp;</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NavView',
  props: {
    showNav: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update: showNav'],
  setup(props, {emit}) {
    const hoverOut = () => {
      emit('update:showNav', false)
    }
    return {hoverOut}
  },
  components: {
  },
  methods: {
    hoverElement(e) {
      if (!this.showNav){
        return
      }
      var element = e.target
      Array.from(element.parentNode.children).forEach(el => {
        el.style.color=''
        Array.from(el.children).filter(p => p.tagName === 'svg')
        .forEach(s => s.style.fill='white')
    });
      var bg = window.getComputedStyle(element.parentNode, null).getPropertyValue('background-color');
      element.style.color=bg
      Array.from(element.children).filter(p => p.tagName === 'svg')
        .forEach(s => s.style.fill=bg)

      this.$refs.hoverer.style.top = `${element.offsetTop}px`;
      this.$refs.hoverer.style.height = `${element.clientHeight}px`;
    },
  }
}
</script>

<style>
@keyframes fly-in {
  0% {
      top: -100%;
  }
  100% {
      top: 120px;
  }
}
.nav-options {
  position: absolute;
  top: 120px;
  display:flex;
  z-index: 3;
  padding-bottom: 16px;
  flex-direction:column;
  width: 100%;
  text-align: center;
  align-self: center;
  /*background-color: red;*/
  background-color:var(--lightmaroon);
  animation: fly-in 250ms cubic-bezier(0.77, 0, 0.175, 1) forwards;
}
.nav-options > * {
  padding: 16px;
  z-index: 1;
  transition: color 250ms cubic-bezier(0.2,1,0.2,1) 50ms;
  color:white;
  text-decoration: none;
  font-weight:800;
  cursor: pointer;
  display:flex;
  justify-content:center;
  letter-spacing: -0.5px;
}
.nav-options > * > svg {
  transition: fill 250ms cubic-bezier(0.2,1,0.2,1) 50ms;
}
.nav-options > *:hover {
}

.hoverer {
  background:white;
  border-radius: 16px;
  position: absolute;
  z-index: 0;
  width:25%;
  left:37.5%;
  top: 0; /* start transitioning from position '0' instead of 'auto' */
  transition: top 250ms cubic-bezier(0.2,1,0.2,1) 0s;
}
/*
.diss-link {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M184 0c30.9 0 56 25.1 56 56V456c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56V56c0-30.9 25.1-56 56-56z'/%3E%3C/svg%3E"), pointer;
}
.chat-link {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z'/%3E%3C/svg%3E"), pointer;
}
.translate-link {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 640 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z'/%3E%3C/svg%3E"), pointer;
}
.opal-link {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z'/%3E%3C/svg%3E"), pointer;
}
*/

</style>
