<template>
  <div class="about">
    <div class="hero">
      <h1>
        <div class="left-word-container">
        <span class="reveal left-word">keart</span>
        </div>
        <span class="reveal center-dot">.</span>
        <div class="right-word-container">
        <span class="reveal right-word">land</span>
        </div>
      </h1>
      <router-link class="reveal-subtitle" :style="`--i:${'keart.land'.length}`" to="chat">chat client</router-link>
      <router-link class="reveal-subtitle" :style="`--i:${'keart.land'.length + 1}`" to="translate">translate tool</router-link>
      <router-link class="reveal-subtitle" :style="`--i:${'keart.land'.length + 2}`" to="opal">opal</router-link>
    </div>
  </div>
</template>

<style>
.about {
  color: black;
}
.about h1 {
  margin: 0;
  text-transform: uppercase;
  gap: 8px;
  display:flex;
  align-items:center;
  font-size: min(150px, 13vw);
}
.about .hero {
  width: 100vw;
  padding-top: calc(33vh - 120px);
  padding-left: 5vw;
  height: calc(100vh - 120px);
}


.reveal {
  letter-spacing: -0.5vw;
  padding-right: 0.5vw;
}
.left-word-container {
  display: flex;
  overflow: hidden;
  align-items:center;
  width: fit-content;
}
@keyframes appearRightFromDot {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0%)
  }
}
.left-word {
  transform: translateX(100%);
  animation: appearRightFromDot 1000ms 700ms cubic-bezier(.86,0,.07,.995) forwards;
}
@keyframes dotAppear {
  0% {
    font-size:0%;
  }
  100% {
    font-size:100%;
  }
}
@keyframes lineToDot {
  0% {
    font-size:100%;
    width:0.5vw;
  }
  100% {
    font-size:0.5vw;
    width:9vw;
  }
}
@keyframes lineToDot2 {
  0% {
    font-size:0.5vw;
    width:9vw;
    margin-top: 0;
    color:transparent;
    background: black;
    margin:0;
  }
  100% {
    color:black;
    background: transparent;
    margin:max(-16px, -3vw);
    width: auto;
    font-size: inherit;
    letter-spacing: 0px;
  }
}
.center-dot {
  padding: 0px;
  width: 0.5vw;
  height:100%;
  background:black;
  color: transparent;
  border-radius: 32px;
  animation: dotAppear 1000ms 0ms cubic-bezier(.86,0,.07,.995) forwards,
              lineToDot 1000ms 1800ms cubic-bezier(.86,0,.07,.995) forwards,
              lineToDot2 1000ms 2800ms cubic-bezier(.86,0,.07,.995) forwards;
}
.right-word-container {
  display: flex;
  overflow: hidden;
  width: fit-content;
}
@keyframes appearLeftFromDot {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0%)
  }
}
.right-word {
  font-weight:300;
  transform: translateX(-100%);
  animation: appearLeftFromDot 1000ms 1000ms cubic-bezier(.86,0,.07,.995) forwards;
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.reveal-subtitle {
  opacity: 0;
  z-index: -1;
  margin-right: -1px;
  animation: fadeInFromLeft 1.0s cubic-bezier(.86,0,.07,.995) forwards;
  animation-delay: calc(50ms * var(--i));
  margin-left: 64px;
  font-size:24px;
}
</style>
