<template>
  <div class="page-swipe up"></div>
  <div class="splash">
    <div class="logo top"></div>
    <div class="logo middle"></div>
    <div class="logo bottom"></div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>

.splash {
  position: fixed;
  box-sizing: border-box;
  margin: auto;
  font-size: 10rem;
  display:flex;
  inset: 0px;
  padding:10px;
  width: fit-content;
  height: fit-content;
  z-index: 10;
  animation: splash 2500ms cubic-bezier(0.77, 0, 0.175, 1) forwards;
  display: flex;
  flex-direction: column;
}

@keyframes splash {
  64% {
      transform: translateY(0%);
      opacity:100%;
      filter: blur(0px);
      -webkit-filter: blur(0px);
  }
  100% {
      transform: translateY(-50%) rotate(40deg);
      opacity:0%;
      filter: blur(16px);
      -webkit-filter: blur(16px);
  }
}


.logo {
  z-index: 15;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}
.logo.top {
  animation: rollInUp 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
             bounceUp 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.logo.middle {
  z-index: 16;
  animation: rollIn 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
             bounce 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.logo.bottom {
  animation: rollInDown 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
             bounceDown 0.5s 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes bounceUp {
  0% {
    transform: translateY(150px);
    width:150px;
    height:150px;
  }
  40% {
    transform: translateY(0px);
    width:210px;
    height:210px;
  }
  100% {
    transform: translateY(0px);
    width:200px;
    height:200px;
  }
}
@keyframes bounce {
  0% {
    width:150px;
    height:150px;
  }
  40% {
    width:210px;
    height:210px;
  }
  100% {
    width:200px;
    height:200px;
  }
}
@keyframes bounceDown {
  0% {
    transform: translateY(-150px);
    width:150px;
    height:150px;
  }
  40% {
    transform: translateY(0px);
    width:210px;
    height:210px;
  }
  100% {
    transform: translateY(0px);
    width:200px;
    height:200px;
  }
}

@keyframes rollIn {
	0% {
		transform: translateX(1000px);
	}
	100% {
		transform: translateX(0px);
	}
}
@keyframes rollInUp {
	0% {
		transform: translateX(1000px) translateY(150px);
	}
	100% {
		transform: translateX(0px) translateY(150px);
	}
}
@keyframes rollInDown {
	0% {
		transform: translateX(1000px) translateY(-150px);
	}
	100% {
		transform: translateX(0px) translateY(-150px);
	}
}

.squiggle {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  animation: draw 2s linear infinite alternate;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.page-swipe {
  position: fixed;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0%);
  z-index: 5;
}

.page-swipe.up {
    animation: swipeUp 2500ms cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

@keyframes swipeUp {
  64% {
      transform: translateY(0%);
  }
  100% {
      transform: translateY(-100%);
  }
}
</style>
