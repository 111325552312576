<template>
  <div v-if="isPageTransitionActiveUp" class="page-swipe up"></div>
</template>

<script>
export default {
  data() {
    return {
      isPageTransitionActiveUp: false,
      animation_ms: 1000,
      current_timeout: null,
    };
  },
  methods: {
    startPageTransition(next) {
      if (!this.isPageTransitionActiveUp){
        this.isPageTransitionActiveUp = false;
        clearTimeout(this.current_timeout);
      }

      setTimeout(() => {
        next();
      }, this.animation_ms*0.45);

      this.isPageTransitionActiveUp = true;
      this.current_timeout = setTimeout(() => {
        this.isPageTransitionActiveUp = false;
      }, this.animation_ms);
    }
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.startPageTransition(next);
    });
  }
};
</script>

<style scoped>
.page-swipe {
  position: fixed;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  z-index: 5;
  background-color:var(--lightmaroon);
}

.page-swipe.up {
  animation: swipeUp 1000ms cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

@keyframes swipeUp {
  0% {
      transform: translateY(100%);
  }
  45% {
      transform: translateY(0%);
  }
  55% {
      transform: translateY(0%);
  }
  100% {
      transform: translateY(-100%);
  }
}

</style>
