<template>
    <FirstPageTransition v-if="first_page_load"/>
    <PageTransition v-if="!first_page_load"/>
    <nav :class="showNav ? '' : 'shadow'">
      <div
          @click="showNav=!showNav;shownNav=true"
          class="nav-logo-container"
          :style="shownNav ? 'animation:none' :''">
        <div class="nav-logo top"></div>
        <div class="nav-logo middle"></div>
        <div class="nav-logo bottom"></div>
      </div>
      <router-link @click="showNav=false" to="/">KEART.LAND</router-link>
    </nav>
    <NavView v-model:showNav="showNav" v-if="showNav" />
    <router-view v-if="this.ready"/>
</template>

<script>
import PageTransition from "@/components/PageTransition.vue";
import FirstPageTransition from "@/components/FirstPageTransition.vue";
import NavView from "@/components/NavView.vue";

export default {
  name: "App",
  data() {
    return {
      first_page_load: true,
      showNav:false,
      shownNav:false,
      ready: false,
    }
  },
  components: {
    PageTransition,
    FirstPageTransition,
    NavView
  },
  mounted() {
    this.first_page_load = true;
    setTimeout(()=>{
      this.ready = true;
    }, 2450)
    setTimeout(()=>{
      this.first_page_load = false;
    }, 2500)
  }
};
</script>

<style>
:root {
  --maroon: #472d30;
  --lightmaroon: #723d46;
  --green: #2b5338;
  --orange:#e26d5c;
  --yellow: #ffe1a8
}
* {
  box-sizing: border-box;
}

@import url("fonts/inter/inter.css");
html, body, #app {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  height:100%;
  display: flex;
  flex-direction: column;
}

nav {
  display:flex;
  align-items:center;
  z-index: 4;
  justify-content: space-between;
  gap: 4px;
  height: 120px;
  min-height: 120px;
}
.shadow {
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
nav a {
  display:flex;
  align-items:center;
  gap: 4px;
  font-weight: 800;
  font-size: 32px;
  text-decoration:none;
  color: white;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  letter-spacing: -1px;
}
nav, .page-swipe {
  background-color: var(--maroon);
}
@keyframes shake {
 0% { transform: translateX(0) }
 40% { transform: translateX(0) }
 45% { transform: translateX(1px) rotate(1deg) }
 50% { transform: translateX(-1px) rotate(-1deg)}
 65% { transform: translateX(1px) rotate(1deg)}
 70% { transform: translateX(0) }
 100% { transform: translateX(0) }
}
.nav-logo-container {
  animation: shake 1500ms cubic-bezier(0.77, 0, 0.175, 1) infinite;
  display: flex;
  flex-direction: column;
  padding: 16px;
  --distance: 60%;
  cursor:pointer;
}
.nav-logo-container:hover {
  --distance: 30%;
  animation: none;
  transform: rotate(0deg);
}
.nav-logo {
  width: 40px;
  height:40px;
  border-radius: 100%;
}
.nav-logo.top {
  transition: margin-bottom 500ms cubic-bezier(0.2,1,0.2,1);
  margin-bottom: calc(-1*var(--distance));
  z-index: 3;
}
.nav-logo.middle {
  z-index: 2;
}
.nav-logo.bottom {
  transition: margin-top 250ms cubic-bezier(0.2,1,0.2,1);
  margin-top: calc(-1*var(--distance));
  z-index: 1;
}
.nav-logo.top, .logo.top {
  background-color:var(--yellow);
}
.nav-logo.middle, .logo.middle {
  background-color:var(--orange);
}
.nav-logo.bottom, .logo.bottom {
  background-color: var(--lightmaroon);
}
</style>
